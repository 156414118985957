@layer tailwind-base, primereact, tailwind-utilities;

@layer tailwind-base {
  @tailwind base;
}

@layer tailwind-utilities {
  @tailwind components;
  @tailwind utilities;
}
@import url("https://fonts.cdnfonts.com/css/z-arista");

.bg-wave {
  clip-path: circle(60% at 78% 45%);
}

@supports not (selector(::-webkit-scrollbar-thumb)) {
  ::-webkit-scrollbar {
    scrollbar-color: rgb(0 0 0 / 80%) rgb(255 255 255 / 10%);
    scrollbar-width: thin;
  }
}

html {
  background-color: #fafafa;
  min-height: 100vh;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
  border-radius: 0.5rem;
  margin-inline: 4px;
}

::-webkit-scrollbar {
  background-color: #f5f5f5;
  scrollbar-width: thin;
}

::-webkit-scrollbar-thumb {
  background-color: #000000;
  border-radius: 0.5rem;
}
